// JS dependency
var aos = require('aos');

// CSS dependency
import '/../node_modules/aos/dist/aos.css';

// Initialisation
aos.init({
  disable : 'mobile'
});
