// JS dependency
var slick = require('slick-carousel');

// CSS dependency
import '/../node_modules/slick-carousel/slick/slick.css';

// Example
$('#slick-home').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  fade: true,
  arrows: false,
  autoplay: true,
  pauseOnHover: false,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 6000,
  responsive: [{
      breakpoint: 1200,
      settings: {}
    },
    {
      breakpoint: 991,
      settings: {}
    },
    {
      breakpoint: 576,
      settings: {}
    },
  ]
});

// Example animations
setTimeout(function() {
  $('#slick .slick-active').addClass('animation');
}, 250);

$('#slick').on('afterChange', function(event, slick, currentSlide) {
  $('#slick .slick-active').addClass('animation');
});

$('#slick').on('beforeChange', function(event, slick, currentSlide) {
  $('#slick .slick-active').removeClass('animation');
});


function height_viewport() {
  var viewport = $(window).height();
  var header = $('#header').innerHeight();
  $('#slick-home .item').height(viewport - header);
}

height_viewport();

$(window).on('resize', function() {
  height_viewport();
});
